import React from 'react';
import './Footer.css'; // Importa los estilos específicos del footer
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
	<footer className="footer">
	  <div className="footer-content">
		{/* Sección de información de contacto */}
		<div className="footer-section contact-info">
		  <h4>Contacto</h4>
		  <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Dirección: Av Enrique Díaz de León Sur 830, Moderna, 44190 Guadalajara, Jal.</p>
		  <p><FontAwesomeIcon icon={faPhone} /> Teléfono: <a href="tel:+523331620414">+52 33 3162 0414</a></p>
		  <p><FontAwesomeIcon icon={faEnvelope} /> Email: <a href="mailto:ventas@cendial.com.mx">ventas@cendial.com.mx</a></p>
		</div>

		{/* Sección de enlaces rápidos */}
		<div className="footer-section quick-links">
		  <h4>Enlaces Rápidos</h4>
		  <ul>
			<li><a href="/">Inicio</a></li>
			<li><a href="/hemodialisis">Hemodiálisis</a></li>
			<li><a href="/servicios">Servicios</a></li>
			<li><a href="/contacto">Contacto</a></li>
			<li><a href="/privacidad">Aviso de Privacidad</a></li>
		  </ul>
		</div>

		{/* Sección de redes sociales */}
		<div className="footer-section social-media">
		  <h4>Síguenos</h4>
		  <div className="social-icons">
			<a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
			  <FontAwesomeIcon icon={faFacebook} />
			</a>
			<a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
			  <FontAwesomeIcon icon={faInstagram} />
			</a>
			<a href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer">
			  <FontAwesomeIcon icon={faTiktok} />
			</a>
		  </div>
		</div>
	  </div>

	  {/* Sección de derechos reservados */}
	  <div className="footer-bottom">
		<p>&copy; {new Date().getFullYear()} Cendial. Todos los derechos reservados.</p>
	  </div>
	</footer>
  );
}

export default Footer;
